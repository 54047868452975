// const testRoutes = [
//   { path: "" },
//   { path: "my-profile" },
//   { path: "leafletmap" },
//   { path: "allFormComponent" },
//   { path: "InputField" },
//   { path: "editor" },
//   { path: "stepperForms" },
//   { path: "FormsWithValidation" },
//   { path: "progress" },
//   { path: "button" },
//   { path: "tab" },
//   { path: "autocomplete" },
//   { path: "checkbox" },
//   { path: "radiobox" },
//   { path: "selectbox" },
//   { path: "transfer" },
//   { path: "alert" },
//   { path: "modal" },
//   { path: "message" },
//   { path: "breadcrumb" },
//   { path: "dropdown" },
//   { path: "op_tag" },
//   { path: "op_timeline" },
//   { path: "uppy" },
//   { path: "dropzone" },
// ];

const options = [
  {
    key: "cms",
    label: "sidebar.cms",
    leftIcon: "ion-ios-cog",
    children: [
      {
        key: "menu",
        label: "sidebar.menu",
      },
      {
        key: "notifications",
        label: "sidebar.notifications",
      },
      {
        key: "pages",
        label: "sidebar.pages",
      },
      {
        key: "settings",
        label: "sidebar.settings",
      },
      {
        key: "translates",
        label: "sidebar.translates",
      },
      {
        key: "users",
        label: "sidebar.users",
      },
      {
        key: "languages",
        label: "sidebar.languages",
      },
    ],
  },
  {
    key: "articles-list",
    label: "sidebar.articles",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "articles",
        label: "sidebar.articles",
      },

      {
        key: "articles-categories",
        label: "sidebar.categoriesArticles",
      },
    ],
  },
  {
    key: "products-list",
    label: "sidebar.products",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "products",
        label: "sidebar.products",
      },
      {
        key: "products-categories",
        label: "sidebar.categoriesProducts",
      },
      {
        key: "filters-categories",
        label: "sidebar.filters_categories",
      },
      {
        key: "filters",
        label: "sidebar.filters",
      },
    ],
  },
  {
    key: "sections",
    label: "sidebar.sections",
    eventKey: "sidebar.sections",
    leftIcon: "ion-ios-list",
    children: [
      {
        key: "sections-slider",
        label: "sidebar.section_slider",
        eventKey: "sidebar.section_slider",
      },
      {
        key: "sections-slider_advantages",
        label: "sidebar.section_slider_advantages",
        eventKey: "sidebar.section_slider_advantages",
      },
      {
        key: "sections-reviews_images",
        label: "sidebar.section_reviews_images",
        eventKey: "sidebar.section_reviews_images",
      },
      {
        key: "sections-reviews",
        label: "sidebar.section_reviews",
        eventKey: "sidebar.section_reviews",
      },
    ],
  },
  {
    key: "delivery-day-min",
    label: "sidebar.minimOrder",
    eventKey: "sidebar.minimOrder",
    leftIcon: "ion-ios-pricetag",
  },
  {
    key: "newsletters",
    label: "sidebar.newsletters",
    eventKey: "sidebar.newsletters",
    leftIcon: "ion-ios-email",
  },
  {
    key: "feedbacks",
    label: "sidebar.feedback",
    eventKey: "sidebar.feedback",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "disabled-dates",
    label: "sidebar.disabledDates",
    eventKey: "sidebar.disabledDates",
    leftIcon: "ion-ios-email",
  },
  {
    key: "discounts",
    label: "sidebar.discounts",
    eventKey: "sidebar.discounts",
    leftIcon: "ion-cash",
  },
  {
    key: "orders",
    label: "sidebar.orders",
    eventKey: "sidebar.orders",
    leftIcon: "ion-cash",
  },
  {
    key: "locations",
    label: "sidebar.locations",
    eventKey: "sidebar.locations",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "messages",
    label: "sidebar.messages",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "messages",
        label: "sidebar.messages",
      },
      {
        key: "for-who-messages",
        label: "sidebar.for_who_messages",
      },
      {
        key: "occasions",
        label: "sidebar.occasions",
      },
    ],
  },
  // {
  //   key: "test",
  //   label: "sidebar.test",
  //   leftIcon: "ion-bag",
  //   children: testRoutes.map((q) => ({
  //     key: q.path,
  //     label: "sidebar." + q.path,
  //   })),
  // },
];
export default options;
