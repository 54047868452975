import * as ACTIONS from "@iso/redux/products/actionTypes";

const initialState = {
  products: {
    list: [],
    total: 0,
  },
};

function productsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        ...{ products: payload },
      };
    }
    default:
      return state;
  }
}

export default productsReducer;
