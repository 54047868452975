export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

export const SHOW_MESSAGE_REQUEST = "SHOW_MESSAGE_REQUEST";
export const SHOW_MESSAGE_SUCCESS = "SHOW_MESSAGE_SUCCESS";

export const POST_MESSAGE_REQUEST = "POST_MESSAGE_REQUEST";
export const POST_MESSAGE_SUCCESS = "POST_MESSAGE_SUCCESS";

export const PUT_MESSAGE_REQUEST = "PUT_MESSAGE_REQUEST";
export const PUT_MESSAGE_SUCCESS = "PUT_MESSAGE_SUCCESS";

export const DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
