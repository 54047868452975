import App from "@iso/redux/app/reducer";
import articlesReducer from "@iso/redux/articles/reducer";
import articlesCategoriesReducer from "@iso/redux/articlesCategories/reducer";
import feedbacksReducer from "@iso/redux/feedbacks/reducer";
import dashboardReducer from "@iso/redux/dashboard/reducer";
import disabledDatesReducer from "@iso/redux/disabledDates/reducer";
import discountsReducer from "@iso/redux/discounts/reducer";
import LanguageReducer from "@iso/redux/languages/reducer";
import mediaReducer from "@iso/redux/medias/reducer";
import menuReducer from "@iso/redux/menu/reducer";
import minimOrdersReducer from "@iso/redux/minimOrder/reducer";
import messagesReducer from "@iso/redux/messages/reducer";
import filtersCategories from "@iso/redux/filtersCategories/reducer";
import filtersReducer from "@iso/redux/filters/reducer";
import forWhoMessagesReducer from "@iso/redux/forWhoMessages/reducer";
import locationsReducer from "@iso/redux/locations/reducer";
import pagesReducer from "@iso/redux/pages/reducer";
import productsCategoriesReducer from "@iso/redux/productsCategories/reducer";
import productsReducer from "@iso/redux/products/reducer";
import ordersReducer from "@iso/redux/orders/reducer";
import occasionsReducer from "@iso/redux/occasions/reducer";
import settingsReducer from "@iso/redux/settings/reducer";
import setionsReducer from "@iso/redux/sections/reducer";
import tagsReducer from "@iso/redux/tags/reducer";
import translatesReducer from "@iso/redux/translates/reducer";
import themeReducer from "@iso/redux/themeSwitcher/reducer";
import usersReducer from "@iso/redux/users/reducer";
import { combineReducers } from "redux";

const reducers = combineReducers({
  Auth: usersReducer,
  Articles: articlesReducer,
  PagesReducer: pagesReducer,
  Tags: tagsReducer,
  Settings: settingsReducer,
  ThemeSwitcher: themeReducer,
  LanguageReducer,
  FeedbacksReducer: feedbacksReducer,
  MenuReducer: menuReducer,
  MediaReducer: mediaReducer,
  MinimOrdersReducer: minimOrdersReducer,
  DashboardReducer: dashboardReducer,
  ProductsCategoriesReducer: productsCategoriesReducer,
  ArticlesCategoriesReducer: articlesCategoriesReducer,
  ProductReducer: productsReducer,
  TranslateReducer: translatesReducer,
  SectionsReducer: setionsReducer,
  DisabledDatesReducer: disabledDatesReducer,
  DiscountsReducer: discountsReducer,
  OrdersReducer: ordersReducer,
  FiltersCategories: filtersCategories,
  FiltersReducer: filtersReducer,
  LocationsReducer: locationsReducer,
  ForWhoMessagesReducer: forWhoMessagesReducer,
  MessagesReducer: messagesReducer,
  OccasionsReducer: occasionsReducer,
  App: App,
});

export default reducers;
