export const GET_DISCOUNTS_REQUEST = "GET_DISCOUNTS_REQUEST";
export const GET_DISCOUNTS_SUCCESS = "GET_DISCOUNTS_SUCCESS";

export const SHOW_DISCOUNT_REQUEST = "SHOW_DISCOUNT_REQUEST";
export const SHOW_DISCOUNT_SUCCESS = "SHOW_DISCOUNT_SUCCESS";

export const POST_DISCOUNT_REQUEST = "POST_DISCOUNT_REQUEST";
export const POST_DISCOUNT_SUCCESS = "POST_DISCOUNT_SUCCESS";

export const PUT_DISCOUNT_REQUEST = "PUT_DISCOUNT_REQUEST";
export const PUT_DISCOUNT_SUCCESS = "PUT_DISCOUNT_SUCCESS";

export const DELETE_DISCOUNT_REQUEST = "DELETE_DISCOUNT_REQUEST";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
