import * as ACTIONS from "@iso/redux/minimOrder/actionTypes";

const initialState = {
  minimOrders: {
    list: [],
    total: 0,
  },
};

function minimOrdersReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_MINIM_ORDERS_SUCCESS: {
      return {
        ...state,
        ...{ articles: payload },
      };
    }
    default:
      return state;
  }
}

export default minimOrdersReducer;
