export const GET_MINIM_ORDERS_REQUEST = "GET_MINIM_ORDERS_REQUEST";
export const GET_MINIM_ORDERS_SUCCESS = "GET_MINIM_ORDERS_SUCCESS";

export const SHOW_MINIM_ORDER_REQUEST = "SHOW_MINIM_ORDER_REQUEST";
export const SHOW_MINIM_ORDER_SUCCESS = "SHOW_MINIM_ORDER_SUCCESS";

export const POST_MINIM_ORDER_REQUEST = "POST_MINIM_ORDER_REQUEST";
export const POST_MINIM_ORDER_SUCCESS = "POST_MINIM_ORDER_SUCCESS";

export const PUT_MINIM_ORDER_REQUEST = "PUT_MINIM_ORDER_REQUEST";
export const PUT_MINIM_ORDER_SUCCESS = "PUT_MINIM_ORDER_SUCCESS";

export const DELETE_MINIM_ORDER_REQUEST = "DELETE_MINIM_ORDER_REQUEST";
export const DELETE_MINIM_ORDER_SUCCESS = "DELETE_MINIM_ORDER_SUCCESS";
