import * as ACTIONS from "@iso/redux/disabledDates/actionTypes";

const initialState = {
  disabledDates: {
    list: [],
    total: 0,
  },
};

function disabledDatesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_DISABLED_DATES_SUCCESS: {
      return {
        ...state,
        ...{ disabledDates: payload },
      };
    }
    default:
      return state;
  }
}

export default disabledDatesReducer;
