export const GET_FOR_WHO_MESSAGES_REQUEST = "GET_FOR_WHO_MESSAGES_REQUEST";
export const GET_FOR_WHO_MESSAGES_SUCCESS = "GET_FOR_WHO_MESSAGES_SUCCESS";

export const SHOW_FOR_WHO_MESSAGE_REQUEST = "SHOW_FOR_WHO_MESSAGE_REQUEST";
export const SHOW_FOR_WHO_MESSAGE_SUCCESS = "SHOW_FOR_WHO_MESSAGE_SUCCESS";

export const POST_FOR_WHO_MESSAGE_REQUEST = "POST_FOR_WHO_MESSAGE_REQUEST";
export const POST_FOR_WHO_MESSAGE_SUCCESS = "POST_FOR_WHO_MESSAGE_SUCCESS";

export const PUT_FOR_WHO_MESSAGE_REQUEST = "PUT_FOR_WHO_MESSAGE_REQUEST";
export const PUT_FOR_WHO_MESSAGE_SUCCESS = "PUT_FOR_WHO_MESSAGE_SUCCESS";

export const DELETE_FOR_WHO_MESSAGE_REQUEST = "DELETE_FOR_WHO_MESSAGE_REQUEST";
export const DELETE_FOR_WHO_MESSAGE_SUCCESS = "DELETE_FOR_WHO_MESSAGE_SUCCESS";
