import * as ACTIONS from "@iso/redux/productsCategories/actionTypes";

const initialState = {
  productsCategories: {
    list: [],
    total: 0,
  },
};

function productsCategoriesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_PRODUCTS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{ productsCategories: payload },
      };
    }

    default:
      return state;
  }
}

export default productsCategoriesReducer;
