import * as ACTIONS from "@iso/redux/discounts/actionTypes";

const initialState = {
  discounts: {
    list: [],
    total: 0,
  },
};

function discountsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        ...{ discounts: payload },
      };
    }
    default:
      return state;
  }
}

export default discountsReducer;
