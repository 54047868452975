export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";

export const SHOW_ORDER_REQUEST = "SHOW_ORDER_REQUEST";
export const SHOW_ORDER_SUCCESS = "SHOW_ORDER_SUCCESS";

export const PUT_ORDER_REQUEST = "PUT_ORDER_REQUEST";
export const PUT_ORDER_SUCCESS = "PUT_ORDER_SUCCESS";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";

export const DOWNLOAD_ORDER_REQUEST = "DOWNLOAD_ORDER_REQUEST";
export const DOWNLOAD_ORDER_SUCCESS = "DOWNLOAD_ORDER_SUCCESS";
