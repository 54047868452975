export const GET_DISABLED_DATES_REQUEST = "GET_DISABLED_DATES_REQUEST";
export const GET_DISABLED_DATES_SUCCESS = "GET_DISABLED_DATES_SUCCESS";

export const SHOW_DISABLED_DATE_REQUEST = "SHOW_DISABLED_DATE_REQUEST";
export const SHOW_DISABLED_DATE_SUCCESS = "SHOW_DISABLED_DATE_SUCCESS";

export const POST_DISABLED_DATE_REQUEST = "POST_DISABLED_DATE_REQUEST";
export const POST_DISABLED_DATE_SUCCESS = "POST_DISABLED_DATE_SUCCESS";

export const PUT_DISABLED_DATE_REQUEST = "PUT_DISABLED_DATE_REQUEST";
export const PUT_DISABLED_DATE_SUCCESS = "PUT_DISABLED_DATE_SUCCESS";

export const DELETE_DISABLED_DATE_REQUEST = "DELETE_DISABLED_DATE_REQUEST";
export const DELETE_DISABLED_DATE_SUCCESS = "DELETE_DISABLED_DATE_SUCCESS";
