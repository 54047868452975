export const GET_FILTERS_REQUEST = "GET_FILTERS_REQUEST";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";

export const SHOW_FILTER_REQUEST = "SHOW_FILTER_REQUEST";
export const SHOW_FILTER_SUCCESS = "SHOW_FILTER_SUCCESS";

export const POST_FILTER_REQUEST = "POST_FILTER_REQUEST";
export const POST_FILTER_SUCCESS = "POST_FILTER_SUCCESS";

export const PUT_FILTER_REQUEST = "PUT_FILTER_REQUEST";
export const PUT_FILTER_SUCCESS = "PUT_FILTER_SUCCESS";

export const DELETE_FILTER_REQUEST = "DELETE_FILTER_REQUEST";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
