export const GET_OCCASIONS_REQUEST = "GET_OCCASIONS_REQUEST";
export const GET_OCCASIONS_SUCCESS = "GET_OCCASIONS_SUCCESS";

export const SHOW_OCCASION_REQUEST = "SHOW_OCCASION_REQUEST";
export const SHOW_OCCASION_SUCCESS = "SHOW_OCCASION_SUCCESS";

export const POST_OCCASION_REQUEST = "POST_OCCASION_REQUEST";
export const POST_OCCASION_SUCCESS = "POST_OCCASION_SUCCESS";

export const PUT_OCCASION_REQUEST = "PUT_OCCASION_REQUEST";
export const PUT_OCCASION_SUCCESS = "PUT_OCCASION_SUCCESS";

export const DELETE_OCCASION_REQUEST = "DELETE_OCCASION_REQUEST";
export const DELETE_OCCASION_SUCCESS = "DELETE_OCCASION_SUCCESS";
