import * as ACTIONS from "@iso/redux/articlesCategories/actionTypes";

const initialState = {
  articlesCategories: {
    list: [],
    total: 0,
  },
};

function articlesCategoriesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_ARTICLES_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{ articlesCategories: payload },
      };
    }

    default:
      return state;
  }
}

export default articlesCategoriesReducer;
