export const GET_ARTICLES_CATEGORIES_REQUEST = "GET_ARTICLES_CATEGORIES_REQUEST";
export const GET_ARTICLES_CATEGORIES_SUCCESS = "GET_ARTICLES_CATEGORIES_SUCCESS";

export const SHOW_ARTICLE_CATEGORY_REQUEST = "SHOW_ARTICLE_CATEGORY_REQUEST";
export const SHOW_ARTICLE_CATEGORY_SUCCESS = "SHOW_ARTICLE_CATEGORY_SUCCESS";

export const POST_ARTICLE_CATEGORY_REQUEST = "POST_ARTICLE_CATEGORY_REQUEST";
export const POST_ARTICLE_CATEGORY_SUCCESS = "POST_ARTICLE_CATEGORY_SUCCESS";

export const PUT_ARTICLE_CATEGORY_REQUEST = "PUT_ARTICLE_CATEGORY_REQUEST";
export const PUT_ARTICLE_CATEGORY_SUCCESS = "PUT_ARTICLE_CATEGORY_SUCCESS";

export const DELETE_ARTICLE_CATEGORY_REQUEST = "DELETE_ARTICLE_CATEGORY_REQUEST";
export const DELETE_ARTICLE_CATEGORY_SUCCESS = "DELETE_ARTICLE_CATEGORY_SUCCESS";
