import * as ACTIONS from "@iso/redux/orders/actionTypes";

const initialState = {
  orders: {
    list: [],
    total: 0,
  },
  order: {},
};

function ordersReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        ...{ orders: payload },
      };
    }
    case ACTIONS.SHOW_ORDER_SUCCESS: {
      return {
        ...state,
        ...{ order: payload },
      };
    }
    default:
      return state;
  }
}

export default ordersReducer;
